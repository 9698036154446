<template>
  <div>
    <vue-apex-charts
      type="line"
      height="350"
      :options="lineAreaChartSpline.chartOptions"
      :series="lineAreaChartSpline.series"
    ></vue-apex-charts>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    Triger: null,
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      lineAreaChartSpline: {
        series: [
          {
            name: "Jumlah Penduduk",
            type: "column",
            data: [
              785979, 806995, 828293, 849809, 871510, 893362, 915361, 937458,
            ],
          },
          {
            name: "Laju Pertumbuhan Penduduk",
            type: "line",
            data: [2.71, 2.67, 2.64, 2.6, 2.55, 2.51, 2.46, 2.41],
          },
        ],

        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 6,
          },
          stroke: {
            curve: "smooth",
            width: [0, 4],
          },
          colors: ["#07980C", "#FF5306"],
          xaxis: {
            categories: [
              "2011",
              "2012",
              "2013",
              "2014",
              "2015",
              "2016",
              "2017",
              "2018",
            ],
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 0,
              opacityFrom: 0.9,
              opacityTo: 0.8,
              stops: [0, 0, 60, 100],
            },
          },
          yaxis: [
            {
              min: 700000,
              max: 950000,
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                },
              },
            },
            {
              opposite: true,

              min: 1.2,
              max: 2.8,
            },
          ],

          tooltip: {
            y: [
              {
                formatter: function (val) {
                  return val + " Jiwa";
                },
              },
              {
                formatter: function (val) {
                  return val + " %";
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>
