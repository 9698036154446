<template>
  <div>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "chart-usia",
  components: {
    VueApexCharts,
  },
  props: {
    Triger: null,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          stacked: true,
        },
        colors: ["#FF5306", "#1490F7"],
        plotOptions: {
          bar: {
            horizontal: true,
            endingShape: "flat",
            columnWidth: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        grid: {
          xaxis: {
            showLines: false,
          },
        },
        yaxis: {
          min: -1500,
          max: 1500,
          title: {
            text: "Range Umur",
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val;
            },
          },
          y: {
            formatter: function (val) {
              return (
                Math.abs(val)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "Jiwa"
              );
            },
          },
        },
        xaxis: {
          categories: [
            "75 +",
            "70-74",
            "65-69",
            "60-64",
            "55-59",
            "50-54",
            "45-49",
            "40-44",
            "35-39",
            "30-34",
            "25-29",
            "20-24",
            "15-19",
            "10-14",
            "5-9",
            "0-4",
          ],
          labels: {
            show: true,
            formatter: function (val) {
              return Math.abs(Math.round(val));
            },
            offsetX: -10,
            offsetY: 0,
            rotate: -45,
            hideOverlappingLabels: false,
            rotateAlways: true,
            trim: true,
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: true,
          position: "top",
        },
      },
    };
  },
  async mounted() {
    // get data stasistik
    this.getChartData();
  },
  methods: {
    getChartData() {
      this.$axios
        .get(
          "/v1/sensus/district/jumlah-penduduk-sesuai-kelompok-usia/" +
            this.Triger
        )
        .then(async (response) => {
          let result = response.data;
          // format data perempuan harus bernilai negatif
          for (let index = 0; index < result.series[0].data.length; index++) {
            result.series[0].data[index] = result.series[0].data[index] * -1;
          }
          // set data
          this.series = result.series;
          this.chartOptions = {
            chart: {
              stacked: true,
            },
            colors: ["#FF5306", "#1490F7"],
            plotOptions: {
              bar: {
                horizontal: true,
                endingShape: "flat",
                columnWidth: "80%",
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 1,
              colors: ["#fff"],
            },
            grid: {
              xaxis: {
                showLines: false,
              },
            },
            yaxis: result.yaxis,
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val;
                },
              },
              y: {
                formatter: function (val) {
                  return (
                    Math.abs(val)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Jiwa"
                  );
                },
              },
            },
            xaxis: {
              categories: result.xaxis.categories,
              labels: {
                show: true,
                formatter: function (val) {
                  return Math.abs(Math.round(val));
                },
                offsetX: -10,
                offsetY: 0,
                rotate: -45,
                hideOverlappingLabels: false,
                rotateAlways: true,
                trim: true,
              },
            },
            fill: {
              opacity: 1,
            },
            legend: {
              show: true,
              position: "top",
            },
          };
        });
    },
  },
};
</script>
