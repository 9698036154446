<template>
  <div>
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
export default {
  name: "chart-air-minum",
  props: {
    Triger: null,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Total Keluarga",
            "PDAM",
            "Sumur Bor",
            "Sumur Umum",
            "Sumur Pribadi",
            "Sungai",
            "Danau",
            "Air Hujan",
            "Mata Air",
            "Air isi Ulang",
            "Lainnya",
            "Membeli",
            "Tidak Membeli",
          ],
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal
            );
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color: "gray",
            },
          },
        },
        legend: {
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: "Bukan OAP",
            data: [20, 80, 10, 26, 80, 10, 16, 60, 10, 16, 80, 15, 25],
            color: "#1460F7",
          },
          {
            name: "OAP",
            data: [36, 120, 20, 36, 120, 20, 36, 120, 20, 36, 120, 25, 45],
            color: "#1490F7",
          },
        ],
      },
    };
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      this.$axios
        .get(
          "/v1/sensus/district/sumber-dan-perolehan-air-minum/" + this.Triger
        )
        .then((response) => {
          let result = response.data;
          this.chartOptions = {
            chart: {
              type: "bar",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: result.xAxis.categories,
            },
            tooltip: {
              formatter: function () {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: "bold",
                  color: "gray",
                },
              },
            },
            legend: {
              enabled: true,
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            series: result.series,
          };
        });
    },
  },
};
</script>
